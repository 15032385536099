<template>
    <div>
    <TitleHelper title="Michael's experiments"></TitleHelper>
        <div style="padding: 10px;">
            <div class="title">
              Michael's experiments
            </div>
            <div class="intro">
              This website hosts web projects produced during lockdown 2020/21.
            </div>
            <div class="linky">
            <div class="link" v-for="p in pages" :key="'link-'+p.link">
              <router-link :to="'/'+p.link+'/'" v-if="p.link != 'quiz' && p.link != 'cov-travel'">
                <img :src="'images/'+p.image" class="image-layout">
                <div class="desc-text">{{p.text}}
                  <div class="extra-text">{{p.desc}}</div>
                </div>
              </router-link>
              <a v-if="p.link=='quiz'" :href="values.BACKEND_CONNECTION + '://' + values.BACKEND_SERVER + ':' + values.BACKEND_SERVER_PORT + '/quiz/'">
                <img :src="'images/'+p.image" class="image-layout" style="">
                <div class="desc-text">{{p.text}}
                  <div class="extra-text">{{p.desc}}</div>
                </div>
              </a>
              <a v-if="p.link=='cov-travel'" :href="values.BACKEND_CONNECTION + '://' + values.BACKEND_SERVER + ':' + values.BACKEND_SERVER_PORT + '/cov-travel'">
                <img :src="'images/'+p.image" class="image-layout" style="">
                <div class="desc-text">{{p.text}}
                  <div class="extra-text">{{p.desc}}</div>
                </div>
              </a>
            </div>
            </div>

        </div>

    </div>
</template>

<script>
import TitleHelper from '../helpers/vueTitleHelper.vue';
const config = require("../assets/config");

export default {
  data() {
    return {
      values: config,
      pages: [{
        text: 'Leaders',
        image: 'leaders.png',
        link: 'date',
        desc: 'Find the world leaders on a date of your choosing. Data extracted from Wikipedia'
      },{
        text: 'Deathlist',
        image: 'deathlist.png',
        link: 'deathlist',
        desc: 'Find out who you have outlived recently, and who you\'re about to. Data extracted from Wikipedia'
      },{
        text: 'Geo Quiz',
        image: 'quiz.png',
        link: 'quiz',
        desc: 'Test your knowledge of world capitals'
      },{
        text: 'Host Jeopardy',
        image: 'jeopardy-host.png',
        link: 'jeopardy-host',
        desc: 'Play Jeopardy online with your friends. Data from J-Archive'
      },{
        text: 'Covid-19',
        image: 'covid.png',
        link: 'covid',
        desc: 'A visualisation of the UK\'s daily coronavirus deaths. Data from gov.uk'
      },{
        text: 'Absolute Radio',
        image: 'absolute.png',
        link: 'absolute',
        desc: 'Find out the most played songs on Absolute Radio. Data from Absolute Radio\'s website'
      },{
        text: 'Watcher',
        image: 'watcher.png',
        link: 'watcher',
        desc: 'Plan your television viewing habits. Data from IMDb'
      },{
        text: 'Booker Prize',
        image: 'booker.png',
        link: 'booker',
        desc: 'See how much the Booker Prize longlist 2020 will cost you to buy on Kindle. Data from amazon.co.uk'
      },{
        text: 'Met Office Weather',
        image: 'weather.png',
        link: 'weather',
        desc: 'Find out your local weather. Data from Met Office APIs'
      },{
        text: 'Covid travel advice',
        image: 'covid-travel.png',
        link: 'cov-travel',
        desc: 'See which countries are currently in travel corridors with England. Data from gov.uk'
      },{
        text: 'Loremen simulator',
        image: 'loremen.png',
        link: 'loremen',
        desc: 'Generate local legends and obscure curiosities from days of yore'
      },{
        text: 'D3 Network Experiment',
        image: 'd3Network.png',
        link: 'd3',
        desc: 'An experiment into designing a network visualisation in D3.js'
      },{
        text: 'Eurovision 2021',
        image: 'eurovision.png',
        link: 'eurovision',
        desc: 'A scorer for the Eurovision Song Contest 2021'
      }]
    }
  },
  beforeCreate: function() {
    document.body.className = 'front-page';
  },
  components: {
    TitleHelper,
  },
};
</script>


<style scoped>

div.link {
  position:relative;
  margin: 9px;
  overflow: hidden;
}

div.desc-text {
    position: absolute;
    left: 4px;
    top: 4px;
    font-family: Avenir, sans-serif;
    color: white;
    font-weight: bolder;
    font-size: larger;
    text-shadow: 2px 2px 6px rgba(0, 0, 0, 1);
    pointer-events: none;
}

div.linky {
  display: inline-flex;
  justify-content: center;
  flex-flow: wrap;
}

img.image-layout {
  height: calc(33vw - 30px);
  max-height: 250px;
  min-height: 100px;
  opacity: 0.75;
  border-radius: 3px;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  transition-property: filter, opacity;
  filter: blur(1px);
  -webkit-filter: blur(1px);
}

img.image-layout:hover {
  opacity: 0.3;
  filter: blur(5px);
  -webkit-filter: blur(5px);
}

div.extra-text {
  margin-top: 8px;
  font-size: 12px;
  text-shadow: none;
  opacity: 0;
  color: #ccc;
  font-style:italic;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  transition-property: opacity;
}

img.image-layout:hover+div div.extra-text {
  opacity: 1;
}

a {
  height: calc(33vw - 30px);
  width: calc(33vw - 30px);
  max-height: 250px;
  max-width: 250px;
  min-height: 100px;
  min-width: 100px;
  display:block;
}

div.title {
  font-family: Avenir, sans-serif;
  font-weight: 500;
  font-size: 30px;
  color: white;
  padding: 20px;
  text-align: center;
}

div.intro {
  font-family: Avenir, sans-serif;
  font-weight: 200;
  font-size: 20px;
  color: white;
  padding: 10px;
  text-align: center;
}

</style>
                                                                     
